// AgencyDetailsTab.styles.js
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    maxWidth: '1920px',
    margin: 'auto',
    padding: theme.spacing(0, 8),
    paddingBottom: theme.spacing(8),
    '@media (max-width: 864px)': {
      padding: theme.spacing(0, 8),
    },
  },
  formButtonRow: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sectionsLayout: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: 'auto',
    gap: 40,
  },
  contactInfoSection: {
    gap: 20,
    '@media(min-width: 1344px)': {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridTemplateRows: '1fr 1fr',
    },
  },
  clientsSection: {
    gap: 20,
  },
  firstNameInput: {
    gridColumn: 'span 6',
  },
  lastNameInput: {
    gridColumn: '7 / span 6',
  },
  phoneInput: {
    gridRow: '2',
    gridColumn: 'span 6',
  },
  emailInput: {
    minWidth: '590px',
    gridRow: '2',
    gridColumn: '7 / span 6',
  },
  bioInput: {
    minWidth: '590px',
    gridRow: '3',
    gridColumn: 'span 6',
  },
  profilePhoto: {
    gridRow: '3',
    gridColumn: '7 / span 2',
    marginLeft: '25%',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 1344px)': {
      marginLeft: '0',
    },
  },
  signaturePhoto: {
    gridRow: '3',
    gridColumn: '9 / span 4',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  aboutMePhoto: {
    gridRow: '4',
    gridColumn: '9 / span 4',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
